// src/pages/PatientLogin.jsx
import React, { useState } from "react";
import { signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from "firebase/auth";
import { auth, googleProvider } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { ref, get, set } from "firebase/database";
import { database } from "../firebaseConfig";

const PatientLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const userRef = ref(database, `users/${userCredential.user.uid}`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        const userData = snapshot.val();
        if (!userData.profileComplete) {
          navigate("/patient-form");
        } else {
          navigate("/dashboard-patient");
        }
      } else {
        navigate("/patient-form");
      }
    } catch (err) {
      setError("Credenziali non valide. Riprova.");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if user exists in database
      const userRef = ref(database, `users/${user.uid}`);
      const snapshot = await get(userRef);
      
      if (!snapshot.exists()) {
        // Create new user in database
        const nameParts = user.displayName ? user.displayName.split(' ') : ['', ''];
        const firstName = nameParts[0] || '';
        const surname = nameParts.slice(1).join(' ');
        
        // Create base user data
        const userData = {
          name: firstName,
          email: user.email,
          userType: 'patient',
          userId: user.uid,
          profileComplete: false,
          createdAt: new Date().toISOString()
        };

        // Add surname only if it exists
        if (surname) {
          userData.surname = surname;
        }
        
        await set(userRef, userData);
        // Redirect to form for new users
        navigate("/patient-form");
      } else {
        // Check if existing user has completed the form
        const userData = snapshot.val();
        if (!userData.profileComplete) {
          navigate("/patient-form");
        } else {
          navigate("/dashboard-patient");
        }
      }
    } catch (err) {
      setError("Errore durante l'accesso con Google. Riprova.");
    }
  };

  const handleResetPassword = async () => {
    if (!email) {
      setError("Inserisci la tua email per reimpostare la password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      setError("");
    } catch (err) {
      setError("Errore nell'invio dell'email di reset. Verifica l'indirizzo email.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleLogin} className="flex flex-col max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <div className="mb-5 flex justify-center">
          <img src="/logo.png" alt="Etherapy" className="h-[100px]" />
        </div>
        <h2 className="text-2xl font-bold mb-7 text-center text-emerald-700">Accesso Paziente</h2>
        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        {resetSent && <p className="text-green-500 mb-2 text-center">Email di reset inviata! Controlla la tua casella di posta.</p>}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="mb-2 p-2 border rounded"
        />
        <button type="submit" className="text-white py-2 px-4 rounded bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-all">
          Accedi come Paziente
        </button>
        
        <div className="my-4 flex items-center">
          <div className="flex-1 border-t border-gray-300"></div>
          <span className="px-4 text-gray-500">oppure</span>
          <div className="flex-1 border-t border-gray-300"></div>
        </div>
        
        <button
          type="button"
          onClick={handleGoogleLogin}
          className="flex items-center justify-center gap-2 py-2 px-4 border border-gray-300 rounded-lg hover:bg-gray-50 transition-all"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="#4285F4"
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
              fill="#34A853"
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
              fill="#FBBC05"
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
              fill="#EA4335"
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
          </svg>
          Accedi con Google
        </button>

        <button
          type="button"
          onClick={handleResetPassword}
          className="text-sm text-blue-900 mt-4 hover:underline text-center"
        >
          Password dimenticata?
        </button>
        
        <p className="text-sm text-center mt-4">
          Non hai ancora un account? <a href="/patientregister" className="text-blue-900">Registrati ora!</a>
        </p>
      </form>
    </div>
  );
};

export default PatientLogin;
