import React, { useState, useEffect } from 'react';
import { ref, onValue, update, set, push } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { findPotentialPatients, validateTherapistProfile, validatePatientData } from '../../utils/matching';
import { MessageCircle, User, LogOut, ChevronDown, ChevronUp, NotebookPen, Trash2, Edit2, Plus, Calendar, Sun, Moon, Save } from 'lucide-react';
import Sidebar from './Sidebar';
import ChatBox from './ChatBox';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import PatientDetailsModal from './PatientDetailsModal';
import { Calendar as CalendarComponent } from '../ui/calendar';
import { Button } from '../ui/button';
import { acceptMatch } from '../../services/matchService';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";

const TherapistDashboard = () => {
  const [therapistProfile, setTherapistProfile] = useState(null);
  const [pendingPatients, setPendingPatients] = useState([]);
  const [acceptedPatients, setAcceptedPatients] = useState([]);
  const [debugInfo, setDebugInfo] = useState({
    therapistValidation: null,
    totalPatients: 0,
    filteredPatients: 0,
    matchingErrors: []
  });
  const [photoURL, setPhotoURL] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [isPatientModalOpen, setIsPatientModalOpen] = useState(false);
  const [openChats, setOpenChats] = useState({});
  const [openNotes, setOpenNotes] = useState({});
  const [notes, setNotes] = useState({});
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [newNoteText, setNewNoteText] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [availableTimeSlots, setAvailableTimeSlots] = useState({});
  const [bookedAppointments, setBookedAppointments] = useState([]);
  const [chatMessages, setChatMessages] = useState({});
  const [newChatMessage, setNewChatMessage] = useState({});
  const [showAvailabilitySection, setShowAvailabilitySection] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showBookingDialog, setShowBookingDialog] = useState(false);
  const [selectedPatientForBooking, setSelectedPatientForBooking] = useState(null);
  const [bookingDate, setBookingDate] = useState(null);
  const [isBooking, setIsBooking] = useState(false);

  // Time slots that can be selected
  const timeSlots = [
    { start: "08:00", end: "13:00", period: "Mattina" },
    { start: "13:00", end: "17:00", period: "Pomeriggio" },
    { start: "17:00", end: "22:00", period: "Sera" }
  ].reduce((acc, { start, end, period }) => {
    const slots = [];
    let current = new Date(`2000-01-01T${start}`);
    const endTime = new Date(`2000-01-01T${end}`);
    
    while (current < endTime) {
      slots.push({
        time: current.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' }),
        period
      });
      current.setMinutes(current.getMinutes() + 30);
    }
    
    return [...acc, ...slots];
  }, []);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('No user ID found');
      return;
    }

    // Add listener for availability
    const availabilityRef = ref(database, `availability/${userId}`);
    const availabilityListener = onValue(availabilityRef, (snapshot) => {
      const data = snapshot.val() || {};
      setAvailableTimeSlots(data);
    });

    const userRef = ref(database, `users/${userId}`);

    // Listener per il profilo del terapeuta
    const therapistListener = onValue(userRef, (snapshot) => {
      const therapistData = snapshot.val();
      setPhotoURL(therapistData?.photoURL);

      // Validazione del profilo del terapeuta
      const therapistValidation = validateTherapistProfile(therapistData);
      if (!therapistValidation.isValid) {
        setDebugInfo(prev => ({
          ...prev,
          therapistValidation,
          matchingErrors: [...prev.matchingErrors, 'Profilo terapeuta non valido']
        }));
        return;
      }

      setTherapistProfile(therapistData);

      // Listener per i dati dei pazienti
      const patientsRef = ref(database, 'users');
      const patientsListener = onValue(patientsRef, (patientsSnapshot) => {
        const allUsers = patientsSnapshot.val();
        console.log('Dati dei pazienti:', allUsers);

        const patients = Object.entries(allUsers)
          .filter(([_, user]) => user.userType === 'patient')
          .map(([id, user]) => ({ ...user, id }));

        const invalidPatients = patients.filter(patient => {
          const validation = validatePatientData(patient);
          return !validation.isValid;
        });

        try {
          // Estrai l'array `matches` dall'oggetto restituito da `findPotentialPatients`
          let result = findPotentialPatients(patients, therapistData);
          let potentialMatches = Array.isArray(result.matches) ? result.matches : [];

          console.log('Pazienti potenzialmente abbinati:', potentialMatches);

          const pending = potentialMatches.filter(patient =>
            patient.matchStatus === 'pending' &&
            (!patient.matchedTherapistId || patient.matchedTherapistId === userId)
          );

          const accepted = potentialMatches.filter(patient =>
            patient.matchStatus === 'matched' && patient.matchedTherapistId === userId
          );

          setPendingPatients(pending);
          setAcceptedPatients(accepted);
          setDebugInfo({
            therapistValidation,
            totalPatients: patients.length,
            filteredPatients: potentialMatches.length,
            matchingErrors: invalidPatients.map(p => `Paziente non valido: ${p.id}`)
          });

        } catch (error) {
          setDebugInfo(prev => ({
            ...prev,
            matchingErrors: [...prev.matchingErrors, error.message]
          }));
        }

      });

      return () => {
        patientsListener();
      };
    });

    return () => {
      therapistListener();
      availabilityListener();
    };
  }, []);

  // Separate effect for appointments
  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    console.log('Setting up appointments listener for therapist:', userId);
    
    const appointmentsListener = onValue(ref(database, 'appointments'), (snapshot) => {
      const data = snapshot.val() || {};
      console.log('Raw appointments data:', data); // Debug log
      
      const allAppointments = Object.entries(data).flatMap(([patientId, patientAppointments]) => {
        if (!patientAppointments || typeof patientAppointments !== 'object') {
          return [];
        }
        return Object.entries(patientAppointments).map(([appointmentId, appointment]) => ({
          ...appointment,
          id: appointmentId,
          patientId
        }));
      });
      
      console.log('Processed appointments:', allAppointments); // Debug log
      
      const therapistAppointments = allAppointments
        .filter(appointment => {
          const isTherapistAppointment = appointment.therapistId === userId;
          console.log('Checking appointment:', appointment, 'isTherapist:', isTherapistAppointment); // Debug log
          return isTherapistAppointment;
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
      
      console.log('Filtered therapist appointments:', therapistAppointments); // Debug log
      setBookedAppointments(therapistAppointments);
    });

    return () => appointmentsListener();
  }, []); // Only run once on mount

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId || !acceptedPatients.length) return;

    const notesRef = ref(database, `therapistNotes/${userId}`);
    onValue(notesRef, (snapshot) => {
      if (snapshot.exists()) {
        const notesData = snapshot.val();
        // Converti ogni entry in array se non lo è già
        const formattedNotes = {};
        Object.keys(notesData).forEach(patientId => {
          formattedNotes[patientId] = Array.isArray(notesData[patientId]) 
            ? notesData[patientId] 
            : notesData[patientId] ? [notesData[patientId]] : [];
        });
        setNotes(formattedNotes);
      } else {
        setNotes({});
      }
    });
  }, [acceptedPatients]);

  // Add chat messages listener
  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId || !acceptedPatients.length) return;

    // Create listeners for each patient's chat
    const chatListeners = acceptedPatients.map(patient => {
      const chatRef = ref(database, `chats/${patient.id}`);
      return onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setChatMessages(prev => ({
            ...prev,
            [patient.id]: Object.values(data).sort((a, b) => a.timestamp - b.timestamp)
          }));
        }
      });
    });

    return () => {
      chatListeners.forEach(unsubscribe => unsubscribe());
    };
  }, [acceptedPatients]);

  const sendMessage = async (patientId) => {
    if (!newChatMessage[patientId]?.trim()) return;

    try {
      const chatRef = ref(database, `chats/${patientId}`);
      const newMessageRef = push(chatRef);

      const messageData = {
        text: newChatMessage[patientId],
        senderId: auth.currentUser.uid,
        senderType: 'therapist',
        timestamp: Date.now(),
      };

      await set(newMessageRef, messageData);
      setNewChatMessage(prev => ({ ...prev, [patientId]: '' }));
    } catch (error) {
      console.error('Errore nell\'invio del messaggio:', error);
    }
  };

  const handlePatientResponse = async (patientId, accept) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      if (accept) {
        // Use acceptMatch from matchService which will also send the email
        await acceptMatch(patientId, userId);
      } else {
        // Just update the status to rejected
        const patientRef = ref(database, `users/${patientId}`);
        await update(patientRef, {
          matchStatus: 'rejected',
          matchedTherapistId: null
        });
      }
    } catch (error) {
      console.error('Errore nell\'aggiornare lo stato del paziente:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Il reindirizzamento verrà gestito dal router/auth listener
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  const toggleChat = (patientId) => {
    setOpenChats(prev => ({
      ...prev,
      [patientId]: !prev[patientId]
    }));
  };

  const toggleNotes = (patientId) => {
    setOpenNotes(prev => ({
      ...prev,
      [patientId]: !prev[patientId]
    }));
  };

  const handleSaveNotes = async (patientId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: notes[patientId]
      });

      // Feedback visivo di successo (opzionale)
      alert('Note salvate con successo');
    } catch (error) {
      console.error('Errore nel salvare le note:', error);
      alert('Errore nel salvare le note');
    }
  };

  const handleSaveNote = async (patientId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId || !newNoteText.trim()) return;

      const newNote = {
        id: Date.now().toString(),
        text: newNoteText,
        createdAt: new Date().toISOString()
      };

      const currentNotes = Array.isArray(notes[patientId]) ? notes[patientId] : [];
      const updatedNotes = [...currentNotes, newNote];

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: updatedNotes
      });

      setNewNoteText('');
    } catch (error) {
      console.error('Errore nel salvare la nota:', error);
      alert('Errore nel salvare la nota');
    }
  };

  const handleUpdateNote = async (patientId, noteId, updatedText) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const currentNotes = Array.isArray(notes[patientId]) ? notes[patientId] : [];
      const updatedNotes = currentNotes.map(note => 
        note.id === noteId ? { ...note, text: updatedText } : note
      );

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: updatedNotes
      });

      setEditingNoteId(null);
    } catch (error) {
      console.error('Errore nell\'aggiornare la nota:', error);
      alert('Errore nell\'aggiornare la nota');
    }
  };

  const handleDeleteNote = async (patientId, noteId) => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      const currentNotes = Array.isArray(notes[patientId]) ? notes[patientId] : [];
      const updatedNotes = currentNotes.filter(note => note.id !== noteId);

      await update(ref(database, `therapistNotes/${userId}`), {
        [patientId]: updatedNotes
      });
    } catch (error) {
      console.error('Errore nell\'eliminare la nota:', error);
      alert('Errore nell\'eliminare la nota');
    }
  };

  const toggleTimeSlot = async (date, timeSlot) => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const dateStr = date.toISOString().split('T')[0];
    const currentSlots = availableTimeSlots[dateStr] || [];
    const updatedSlots = currentSlots.includes(timeSlot)
      ? currentSlots.filter(slot => slot !== timeSlot)
      : [...currentSlots, timeSlot].sort();

    try {
      await set(ref(database, `availability/${userId}/${dateStr}`), updatedSlots);
    } catch (error) {
      console.error('Error updating availability:', error);
    }
  };

  const handleCancelAppointment = async (appointment) => {
    try {
      // Restore the time slot in availability
      const appointmentDate = new Date(appointment.date);
      const dateStr = appointmentDate.toISOString().split('T')[0];
      
      // Get current slots for that date
      const currentSlots = availableTimeSlots[dateStr] || [];
      
      // Add the cancelled slot back and sort
      const updatedSlots = [...currentSlots, appointment.time].sort();
      
      // Update availability
      await set(ref(database, `availability/${therapistProfile.id}/${dateStr}`), updatedSlots);
      
      // Update appointment status in patient's appointments
      const appointmentRef = ref(database, `appointments/${appointment.patientId}/${appointment.id}`);
      await update(appointmentRef, {
        status: 'cancelled',
        cancelledAt: new Date().toISOString(),
        cancelledBy: 'therapist'
      });

      setShowCancelDialog(false);
      setSelectedAppointment(null);
      alert("Appuntamento cancellato con successo");
    } catch (error) {
      console.error('Error cancelling appointment:', error);
      alert("Errore durante la cancellazione dell'appuntamento");
    }
  };

  const handleBookAppointment = async (timeSlot) => {
    if (!bookingDate || !selectedPatientForBooking || !timeSlot) return;
    if (isBooking) return;

    const userId = auth.currentUser?.uid;
    if (!userId) {
      alert("Errore: ID terapeuta non trovato");
      return;
    }

    try {
      setIsBooking(true);

      const appointmentData = {
        time: timeSlot,
        date: bookingDate.toISOString(),
        therapistId: userId,
        therapistName: therapistProfile?.name || '',
        therapistSurname: therapistProfile?.surname || '',
        patientId: selectedPatientForBooking.id,
        name: selectedPatientForBooking.name,
        status: 'scheduled',
        createdAt: new Date().toISOString()
      };

      // Add surname only if it exists
      if (selectedPatientForBooking.surname) {
        appointmentData.surname = selectedPatientForBooking.surname;
      }

      const appointmentRef = ref(database, `appointments/${selectedPatientForBooking.id}`);
      const newAppointmentRef = push(appointmentRef);
      await set(newAppointmentRef, appointmentData);

      // Remove the booked slot from availability
      const dateStr = bookingDate.toISOString().split('T')[0];
      const updatedSlots = (availableTimeSlots[dateStr] || []).filter(slot => slot !== timeSlot);
      await set(ref(database, `availability/${userId}/${dateStr}`), updatedSlots);

      setShowBookingDialog(false);
      setSelectedPatientForBooking(null);
      setBookingDate(null);
      alert("Appuntamento prenotato con successo!");
    } catch (error) {
      console.error('Error booking appointment:', error);
      alert("Errore nella prenotazione");
    } finally {
      setIsBooking(false);
    }
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      {/* Sidebar - Hidden on mobile, shown on desktop */}
      <div className="hidden md:block fixed inset-y-0 left-0 w-[280px] bg-white border-r border-gray-200 z-20">
        <Sidebar />
      </div>

      {/* Mobile Sidebar */}
      <div className="md:hidden">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="flex-1 w-full md:pl-[280px]">
        <div className="w-full p-4 md:p-8">
          {/* Header */}
          <header className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 bg-white rounded-xl p-4 md:p-6 shadow-sm w-full mt-16 md:mt-0">
            <div className="flex items-center gap-4 w-full md:w-auto">
              <div className="w-12 md:w-14 h-12 md:h-14 rounded-full overflow-hidden bg-gray-100 ring-2 ring-emerald-500/20">
                {photoURL ? (
                  <img 
                    src={photoURL} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <User className="w-6 md:w-7 h-6 md:h-7 text-gray-400" />
                  </div>
                )}
              </div>
              <div>
                <h1 className="text-xl md:text-2xl font-serif text-emerald-800 font-semibold">
                  Dashboard Terapeuta
                </h1>
                <p className="text-gray-500 text-sm">
                  {therapistProfile?.name} {therapistProfile?.surname}
                </p>
              </div>
            </div>
            <button
              onClick={handleLogout}
              className="mt-4 md:mt-0 w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 text-gray-600 hover:text-red-600 transition-colors rounded-lg hover:bg-red-50"
            >
              <LogOut className="w-5 h-5" />
              <span>Logout</span>
            </button>
          </header>

          {/* Quick Actions - Moved above the main grid */}
          <div className="mb-6">
            <h2 className="text-lg md:text-xl font-serif text-emerald-800 font-semibold mb-4 flex items-center gap-2">
              <Calendar className="w-5 h-5" />
              Azioni Rapide
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <Button
                onClick={() => setShowAvailabilitySection(true)}
                className="w-full bg-emerald-600 hover:bg-emerald-700 text-white flex items-center justify-center gap-2 h-12"
              >
                <Calendar className="w-4 h-4" />
                Gestisci disponibilità
              </Button>
              <Button
                onClick={() => setShowBookingDialog(true)}
                className="w-full bg-white hover:bg-emerald-50 border border-emerald-200 text-emerald-700 flex items-center justify-center gap-2 h-12"
              >
                <Plus className="w-4 h-4 text-emerald-700" />
                <span className="text-emerald-700">Prenota per paziente</span>
              </Button>
            </div>
          </div>

          {/* Grid Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-4 md:gap-6 w-full">
            {/* Main Content Column */}
            <div className="space-y-4 md:space-y-6">
              {/* Appointments Section - Most important, so it goes first */}
              <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                <CardHeader className="border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <CardTitle className="text-lg md:text-xl font-serif text-emerald-800 font-semibold flex items-center gap-2">
                      <Calendar className="w-5 h-5" />
                      Appuntamenti Prenotati
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="divide-y divide-gray-100">
                    {bookedAppointments.length > 0 ? (
                      <ul className="divide-y divide-emerald-100">
                        {bookedAppointments.map((appointment) => (
                          <li key={appointment.id} className="p-4 flex justify-between items-center hover:bg-emerald-50/50 transition-colors">
                            <div className="flex items-center gap-4">
                              <div className="text-emerald-700">
                                <p className="text-sm font-medium">
                                  {new Date(appointment.date).toLocaleDateString('it-IT')}
                                </p>
                                <p className="text-xs text-emerald-600">
                                  {appointment.time}
                                </p>
                              </div>
                              <div>
                                <p className="text-sm font-medium">{appointment.name} {appointment.surname}</p>
                              </div>
                            </div>
                            <div className="flex items-center gap-2">
                              <span
                                className={`text-xs px-3 py-1 rounded-full ${
                                  appointment.status === 'scheduled'
                                    ? 'bg-emerald-100 text-emerald-700'
                                    : appointment.status === 'cancelled'
                                    ? 'bg-red-100 text-red-700'
                                    : 'bg-gray-100 text-gray-700'
                                }`}
                              >
                                {appointment.status === 'scheduled' ? 'Confermato' : 
                                 appointment.status === 'cancelled' ? 'Cancellato' : appointment.status}
                              </span>
                              {appointment.status === 'scheduled' && new Date(appointment.date) > new Date() && (
                                <button
                                  onClick={() => {
                                    setSelectedAppointment(appointment);
                                    setShowCancelDialog(true);
                                  }}
                                  className="text-red-600 hover:text-red-700 text-sm"
                                >
                                  Cancella
                                </button>
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="p-6 text-center text-gray-500">
                        Nessun appuntamento prenotato
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              {/* Accepted Patients Section - Second most important */}
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-4 md:p-6 border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg md:text-xl font-serif text-emerald-800 font-semibold flex items-center gap-2">
                      <User className="w-5 h-5" />
                      Pazienti Accettati
                    </h2>
                    <span className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                      {acceptedPatients.length} attivi
                    </span>
                  </div>
                </div>
                <div className="divide-y divide-gray-100">
                  {acceptedPatients.map(patient => (
                    <div key={patient.id} className="transition-all duration-200">
                      <div className="p-4 md:p-6 hover:bg-gray-50">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
                          <div className="cursor-pointer hover:text-emerald-700 mb-4 sm:mb-0">
                            <h3 className="font-medium text-gray-900">{patient.name} {patient.surname}</h3>
                            <p className="text-sm text-gray-500">{patient.email}</p>
                          </div>
                          <div className="flex flex-wrap gap-2">
                            <button 
                              onClick={() => {
                                setSelectedPatient(patient);
                                setIsPatientModalOpen(true);
                              }}
                              className="inline-flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors text-gray-600 hover:bg-gray-100"
                            >
                              <User className="w-4 h-4" />
                              <span className="text-sm">Info</span>
                            </button>
                            
                            <button 
                              onClick={() => toggleNotes(patient.id)}
                              className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors ${
                                openNotes[patient.id] 
                                  ? 'bg-emerald-100 text-emerald-700' 
                                  : 'text-gray-600 hover:bg-gray-100'
                              }`}
                            >
                              <NotebookPen className="w-4 h-4" />
                              <span className="text-sm">Note</span>
                              {openNotes[patient.id] ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                            </button>
                            
                            <button 
                              onClick={() => toggleChat(patient.id)}
                              className={`inline-flex items-center gap-2 px-3 py-1.5 rounded-lg transition-colors ${
                                openChats[patient.id] 
                                  ? 'bg-emerald-100 text-emerald-700' 
                                  : 'text-gray-600 hover:bg-gray-100'
                              }`}
                            >
                              <MessageCircle className="w-4 h-4" />
                              <span className="text-sm">Chat</span>
                              {openChats[patient.id] ? <ChevronUp className="w-4 h-4" /> : <ChevronDown className="w-4 h-4" />}
                            </button>
                          </div>
                        </div>

                        {openNotes[patient.id] && (
                          <div className="mt-4 space-y-4 border-t pt-4">
                            <div className="space-y-3">
                              {(Array.isArray(notes[patient.id]) ? notes[patient.id] : []).map(note => (
                                <div key={note.id} className="bg-gray-50 rounded-lg p-4">
                                  {editingNoteId === note.id ? (
                                    <div className="space-y-2">
                                      <textarea
                                        value={note.text}
                                        onChange={(e) => {
                                          const updatedNotes = notes[patient.id].map(n =>
                                            n.id === note.id ? { ...n, text: e.target.value } : n
                                          );
                                          setNotes({ ...notes, [patient.id]: updatedNotes });
                                        }}
                                        className="w-full p-2 border rounded text-sm"
                                        rows="3"
                                      />
                                      <div className="flex justify-end gap-2">
                                        <button
                                          onClick={() => handleUpdateNote(patient.id, note.id, note.text)}
                                          className="bg-emerald-500 text-white rounded px-3 py-1 text-sm hover:bg-emerald-600"
                                        >
                                          Salva
                                        </button>
                                        <button
                                          onClick={() => setEditingNoteId(null)}
                                          className="bg-gray-200 text-gray-700 rounded px-3 py-1 text-sm hover:bg-gray-300"
                                        >
                                          Annulla
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="flex justify-between items-start mb-2">
                                        <p className="text-sm text-gray-700">{note.text}</p>
                                        <div className="flex gap-2 ml-4">
                                          <button
                                            onClick={() => setEditingNoteId(note.id)}
                                            className="text-gray-500 hover:text-emerald-600"
                                          >
                                            <Edit2 className="w-4 h-4" />
                                          </button>
                                          <button
                                            onClick={() => handleDeleteNote(patient.id, note.id)}
                                            className="text-gray-500 hover:text-red-600"
                                          >
                                            <Trash2 className="w-4 h-4" />
                                          </button>
                                        </div>
                                      </div>
                                      <p className="text-xs text-gray-400">
                                        {new Date(note.createdAt).toLocaleDateString('it-IT', {
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                          hour: '2-digit',
                                          minute: '2-digit'
                                        })}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>

                            <div className="space-y-2">
                              <textarea
                                value={newNoteText}
                                onChange={(e) => setNewNoteText(e.target.value)}
                                placeholder="Scrivi una nuova nota..."
                                className="w-full p-3 border rounded-lg text-sm text-gray-700 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                                rows="3"
                              />
                              <div className="flex justify-end">
                                <button
                                  onClick={() => handleSaveNote(patient.id)}
                                  disabled={!newNoteText.trim()}
                                  className="inline-flex items-center gap-2 bg-emerald-500 text-white rounded-lg px-4 py-2 text-sm hover:bg-emerald-600 transition-colors disabled:opacity-50"
                                >
                                  <Plus className="w-4 h-4" />
                                  Aggiungi nota
                                </button>
                              </div>
                            </div>
                          </div>
                        )}

                        {openChats[patient.id] && (
                          <div className="mt-4 space-y-4 border-t pt-4">
                            <div className="min-h-[200px] max-h-[300px] overflow-y-auto p-4 bg-gray-50 rounded-lg">
                              {chatMessages[patient.id]?.length > 0 ? (
                                <div className="space-y-3">
                                  {chatMessages[patient.id].map((message, index) => (
                                    <div
                                      key={index}
                                      className={`flex ${message.senderType === 'therapist' ? 'justify-end' : 'justify-start'}`}
                                    >
                                      <div
                                        className={`max-w-[70%] px-3 py-2 rounded-lg ${
                                          message.senderType === 'therapist'
                                            ? 'bg-emerald-600 text-white'
                                            : 'bg-white border border-gray-200'
                                        }`}
                                      >
                                        <p className="text-sm">{message.text}</p>
                                        <span className="text-xs mt-1 block opacity-70">
                                          {new Date(message.timestamp).toLocaleTimeString('it-IT', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                          })}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="text-sm text-gray-500 text-center">
                                  Inizio della conversazione
                                </div>
                              )}
                            </div>
                            <div className="flex gap-2">
                              <input
                                type="text"
                                value={newChatMessage[patient.id] || ''}
                                onChange={(e) => setNewChatMessage(prev => ({
                                  ...prev,
                                  [patient.id]: e.target.value
                                }))}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    sendMessage(patient.id);
                                  }
                                }}
                                placeholder="Scrivi un messaggio..."
                                className="flex-1 p-2 border rounded-lg text-sm text-gray-700 focus:ring-2 focus:ring-emerald-500 focus:border-emerald-500"
                              />
                              <button 
                                onClick={() => sendMessage(patient.id)}
                                className="bg-emerald-500 text-white rounded-lg px-4 py-2 text-sm hover:bg-emerald-600 transition-colors"
                              >
                                Invia
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  {acceptedPatients.length === 0 && (
                    <div className="p-6 text-center text-gray-500">
                      Nessun paziente accettato
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Sidebar Column */}
            <div className="space-y-4 md:space-y-6">
              {/* Pending Patients Section - Moved to sidebar for better visibility */}
              <div className="bg-white rounded-xl shadow-sm overflow-hidden">
                <div className="p-4 md:p-6 border-b border-gray-100">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg md:text-xl font-serif text-emerald-800 font-semibold">
                      Pazienti in Attesa
                    </h2>
                    <span className="bg-emerald-100 text-emerald-800 px-3 py-1 rounded-full text-sm">
                      {pendingPatients.length} in attesa
                    </span>
                  </div>
                </div>
                <div className="divide-y divide-gray-100">
                  {pendingPatients.map(patient => (
                    <div key={patient.id} className="p-4 md:p-6 hover:bg-gray-50 transition-colors">
                      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
                        <div>
                          <h3 className="font-medium text-gray-900">{patient.name} {patient.surname}</h3>
                          <p className="text-sm text-gray-500">{patient.email}</p>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row gap-3">
                        <button
                          onClick={() => handlePatientResponse(patient.id, true)}
                          className="flex-1 bg-emerald-500 text-white rounded-lg px-4 py-2 hover:bg-emerald-600 transition-colors"
                        >
                          Accetta
                        </button>
                        <button
                          onClick={() => handlePatientResponse(patient.id, false)}
                          className="flex-1 bg-white border border-gray-200 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-50 transition-colors"
                        >
                          Rifiuta
                        </button>
                      </div>
                    </div>
                  ))}
                  {pendingPatients.length === 0 && (
                    <div className="p-6 text-center text-gray-500">
                      Nessun paziente in attesa
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PatientDetailsModal 
        patient={selectedPatient}
        open={isPatientModalOpen}
        onOpenChange={setIsPatientModalOpen}
      />
      <Dialog open={showAvailabilitySection} onOpenChange={setShowAvailabilitySection}>
        <DialogContent className="max-w-5xl max-h-[90vh] flex flex-col p-6">
          <DialogHeader className="px-2">
            <DialogTitle className="text-lg font-medium text-emerald-800 flex items-center gap-2">
              <Calendar className="w-5 h-5" />
              Gestione Disponibilità
            </DialogTitle>
          </DialogHeader>

          <div className="flex-1 overflow-y-auto px-1">
            <div className="grid md:grid-cols-[300px,1fr] gap-6 mt-4">
              <div className="space-y-4">
                <div className="bg-white rounded-lg border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-800 mb-3">Gestione Disponibilità</h4>
                  <CalendarComponent
                    mode="single"
                    selected={selectedDate}
                    onSelect={setSelectedDate}
                    disabled={{ before: new Date() }}
                    className="rounded-lg border-0"
                  />
                  
                  <div className="mt-4 space-y-3">
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <div className="w-3 h-3 rounded-full bg-emerald-500"></div>
                      <span>Slot disponibili</span>
                    </div>
                    <div className="flex items-center gap-2 text-sm text-gray-600">
                      <div className="w-3 h-3 rounded-full bg-gray-200"></div>
                      <span>Slot non disponibili</span>
                    </div>
                  </div>
                </div>

                <div className="bg-blue-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-blue-800 mb-2">Gestione Rapida</h4>
                  <div className="space-y-3">
                    <Button
                      onClick={() => {
                        const dateStr = selectedDate.toISOString().split('T')[0];
                        const morningSlots = timeSlots
                          .filter(slot => slot.period === 'Mattina')
                          .map(slot => slot.time);
                        set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), morningSlots);
                      }}
                      variant="outline"
                      size="sm"
                      className="w-full justify-start text-blue-700 bg-white"
                    >
                      <Sun className="w-4 h-4 mr-2 text-amber-500" />
                      Disponibilità mattina
                    </Button>
                    
                    <Button
                      onClick={() => {
                        const dateStr = selectedDate.toISOString().split('T')[0];
                        const afternoonSlots = timeSlots
                          .filter(slot => slot.period === 'Pomeriggio')
                          .map(slot => slot.time);
                        set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), afternoonSlots);
                      }}
                      variant="outline"
                      size="sm"
                      className="w-full justify-start text-blue-700 bg-white"
                    >
                      <Sun className="w-4 h-4 mr-2 text-orange-500" />
                      Disponibilità pomeriggio
                    </Button>
                    
                    <Button
                      onClick={() => {
                        const dateStr = selectedDate.toISOString().split('T')[0];
                        const eveningSlots = timeSlots
                          .filter(slot => slot.period === 'Sera')
                          .map(slot => slot.time);
                        set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), eveningSlots);
                      }}
                      variant="outline"
                      size="sm"
                      className="w-full justify-start text-blue-700 bg-white"
                    >
                      <Moon className="w-4 h-4 mr-2 text-indigo-500" />
                      Disponibilità sera
                    </Button>
                    
                    <Button
                      onClick={() => {
                        const dateStr = selectedDate.toISOString().split('T')[0];
                        set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), []);
                      }}
                      variant="outline"
                      size="sm"
                      className="w-full justify-start text-red-700 bg-white border-red-200 hover:bg-red-50"
                    >
                      <Trash2 className="w-4 h-4 mr-2" />
                      Rimuovi disponibilità
                    </Button>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg border border-gray-200 p-6">
                {selectedDate ? (
                  <>
                    <div className="flex items-center justify-between mb-6">
                      <div className="flex items-center gap-2">
                        <Calendar className="w-5 h-5 text-emerald-600" />
                        <h3 className="text-lg font-medium text-gray-900">
                          {selectedDate.toLocaleDateString('it-IT', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long'
                          })}
                        </h3>
                      </div>
                      <div className="text-sm text-gray-500">
                        {availableTimeSlots[selectedDate.toISOString().split('T')[0]]?.length || 0} slot disponibili
                      </div>
                    </div>

                    <div className="space-y-6">
                      {['Mattina', 'Pomeriggio', 'Sera'].map(period => {
                        const periodSlots = timeSlots.filter(slot => slot.period === period);
                        const dateStr = selectedDate.toISOString().split('T')[0];
                        const availableSlots = availableTimeSlots[dateStr] || [];

                        return (
                          <div key={period} className="space-y-3">
                            <div className="flex items-center justify-between">
                              <h4 className="text-sm font-medium text-gray-700 flex items-center gap-2">
                                {period === 'Mattina' && <Sun className="w-4 h-4 text-amber-500" />}
                                {period === 'Pomeriggio' && <Sun className="w-4 h-4 text-orange-500" />}
                                {period === 'Sera' && <Moon className="w-4 h-4 text-indigo-500" />}
                                {period}
                              </h4>
                              <Button
                                onClick={() => {
                                  const currentSlots = availableTimeSlots[dateStr] || [];
                                  const periodTimes = periodSlots.map(s => s.time);
                                  const allPeriodSelected = periodTimes.every(time => currentSlots.includes(time));
                                  
                                  if (allPeriodSelected) {
                                    // Remove all slots for this period
                                    const newSlots = currentSlots.filter(time => !periodTimes.includes(time));
                                    set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), newSlots);
                                  } else {
                                    // Add all slots for this period
                                    const newSlots = [...new Set([...currentSlots, ...periodTimes])].sort();
                                    set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), newSlots);
                                  }
                                }}
                                variant="outline"
                                size="sm"
                                className="text-xs"
                              >
                                {periodSlots.every(slot => availableSlots.includes(slot.time)) 
                                  ? 'Deseleziona tutti' 
                                  : 'Seleziona tutti'}
                              </Button>
                            </div>
                            <div className="grid grid-cols-4 sm:grid-cols-6 gap-2">
                              {periodSlots.map(({ time }) => {
                                const isAvailable = availableSlots.includes(time);
                                const isBooked = bookedAppointments.some(
                                  apt => apt.date.split('T')[0] === dateStr && apt.time === time
                                );

                                return (
                                  <button
                                    key={time}
                                    onClick={() => {
                                      if (isBooked) return;
                                      const currentSlots = availableTimeSlots[dateStr] || [];
                                      const newSlots = isAvailable
                                        ? currentSlots.filter(t => t !== time)
                                        : [...currentSlots, time].sort();
                                      set(ref(database, `availability/${auth.currentUser.uid}/${dateStr}`), newSlots);
                                    }}
                                    disabled={isBooked}
                                    className={`
                                      text-sm py-2 px-3 rounded-lg transition-all duration-200
                                      ${isBooked 
                                        ? 'bg-blue-50 text-blue-700 cursor-not-allowed'
                                        : isAvailable
                                          ? 'bg-emerald-600 text-white shadow-sm hover:bg-emerald-700'
                                          : 'bg-gray-50 text-gray-700 hover:bg-gray-100'
                                      }
                                    `}
                                  >
                                    {time}
                                    {isBooked && (
                                      <div className="text-[10px] text-blue-600 mt-1">Prenotato</div>
                                    )}
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center py-12">
                    <Calendar className="w-16 h-16 text-gray-200 mb-4" />
                    <p className="text-gray-500 text-center">
                      Seleziona una data dal calendario per gestire le tue disponibilità
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <DialogFooter className="mt-6 border-t pt-4 flex-shrink-0 px-2">
            <div className="flex items-center justify-between w-full">
              <div className="text-sm text-gray-500">
                {selectedDate && (
                  <span>
                    {availableTimeSlots[selectedDate.toISOString().split('T')[0]]?.length || 0} slot selezionati per {selectedDate.toLocaleDateString('it-IT', {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long'
                    })}
                  </span>
                )}
              </div>
              <div className="flex gap-3">
                <Button
                  variant="outline"
                  onClick={() => setShowAvailabilitySection(false)}
                >
                  Annulla
                </Button>
                <Button
                  onClick={() => {
                    setShowAvailabilitySection(false);
                  }}
                  className="bg-emerald-600 text-white hover:bg-emerald-700"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Salva disponibilità
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <DialogContent className="sm:max-w-md p-6">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl">Cancella Appuntamento</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 py-4">
            <p className="text-sm text-gray-600">
              Sei sicuro di voler cancellare questo appuntamento?
            </p>
            {selectedAppointment && (
              <div className="bg-gray-50 p-6 rounded-lg space-y-3">
                <p className="text-sm font-medium">Data: {new Date(selectedAppointment.date).toLocaleDateString('it-IT')}</p>
                <p className="text-sm">Ora: {selectedAppointment.time}</p>
                <p className="text-sm">Paziente: {selectedAppointment.name} {selectedAppointment.surname}</p>
              </div>
            )}
          </div>
          <DialogFooter className="mt-6 pt-4 gap-3">
            <Button
              variant="outline"
              onClick={() => {
                setShowCancelDialog(false);
                setSelectedAppointment(null);
              }}
              className="px-5"
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={() => selectedAppointment && handleCancelAppointment(selectedAppointment)}
              className="bg-red-600 hover:bg-red-700 text-white px-5"
            >
              Conferma Cancellazione
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={showBookingDialog} onOpenChange={setShowBookingDialog}>
        <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col p-6">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl">Prenota Appuntamento</DialogTitle>
          </DialogHeader>

          <div className="flex-1 overflow-y-auto">
            <div className="grid md:grid-cols-[300px,1fr] gap-6">
              <div className="space-y-4">
                {/* Patient Selection */}
                <div className="bg-white rounded-lg border border-gray-200 p-4">
                  <h4 className="text-sm font-medium text-gray-800 mb-3">Seleziona Paziente</h4>
                  <div className="space-y-2">
                    {acceptedPatients.map((patient) => (
                      <button
                        key={patient.id}
                        onClick={() => setSelectedPatientForBooking(patient)}
                        className={`w-full text-left p-3 rounded-lg transition-colors ${
                          selectedPatientForBooking?.id === patient.id
                            ? 'bg-emerald-50 text-emerald-700 border border-emerald-200'
                            : 'hover:bg-gray-50 text-gray-700'
                        }`}
                      >
                        <p className="font-medium">{patient.name} {patient.surname}</p>
                        <p className="text-xs text-gray-500">{patient.email}</p>
                      </button>
                    ))}
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                {selectedPatientForBooking ? (
                  <div className="bg-white rounded-lg border border-gray-200 p-4">
                    <h4 className="text-sm font-medium text-gray-800 mb-3">Seleziona Data</h4>
                    <CalendarComponent
                      mode="single"
                      selected={bookingDate}
                      onSelect={setBookingDate}
                      disabled={(date) => {
                        const now = new Date();
                        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                        const compareDate = new Date(date);
                        compareDate.setHours(0, 0, 0, 0);

                        if (compareDate < today) return true;
                        const maxDate = new Date(today);
                        maxDate.setDate(today.getDate() + 30);
                        if (compareDate > maxDate) return true;
                        return false;
                      }}
                      className="rounded-lg border-0"
                    />
                  </div>
                ) : (
                  <div className="bg-white rounded-lg border border-gray-200 p-6 flex items-center justify-center">
                    <p className="text-gray-500">Seleziona prima un paziente per vedere il calendario</p>
                  </div>
                )}

                <div className="bg-white rounded-lg border border-gray-200 p-6">
                  {selectedPatientForBooking && bookingDate ? (
                    <>
                      <div className="flex items-center gap-2 mb-6">
                        <Calendar className="w-5 h-5 text-emerald-600" />
                        <h3 className="text-lg font-medium text-gray-900">
                          {bookingDate.toLocaleDateString('it-IT', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long'
                          })}
                        </h3>
                      </div>

                      <div className="space-y-6">
                        {['Mattina', 'Pomeriggio', 'Sera'].map(period => {
                          const periodSlots = timeSlots.filter(slot => slot.period === period);
                          const dateStr = bookingDate.toISOString().split('T')[0];
                          const availableSlots = availableTimeSlots[dateStr] || [];

                          return (
                            <div key={period} className="space-y-3">
                              <h4 className="text-sm font-medium text-gray-700 flex items-center gap-2">
                                {period === 'Mattina' && <Sun className="w-4 h-4 text-amber-500" />}
                                {period === 'Pomeriggio' && <Sun className="w-4 h-4 text-orange-500" />}
                                {period === 'Sera' && <Moon className="w-4 h-4 text-indigo-500" />}
                                {period}
                              </h4>
                              <div className="grid grid-cols-4 sm:grid-cols-6 gap-2">
                                {periodSlots.map(({ time }) => {
                                  const isAvailable = availableSlots.includes(time);
                                  const isBooked = bookedAppointments.some(
                                    apt => apt.date.split('T')[0] === dateStr && apt.time === time
                                  );

                                  return (
                                    <button
                                      key={time}
                                      onClick={() => {
                                        if (isAvailable && !isBooked) {
                                          handleBookAppointment(time);
                                        }
                                      }}
                                      disabled={!isAvailable || isBooked}
                                      className={`
                                        text-sm py-2 px-3 rounded-lg transition-all duration-200
                                        ${isBooked 
                                          ? 'bg-blue-50 text-blue-700 cursor-not-allowed'
                                          : isAvailable
                                            ? 'bg-emerald-600 text-white shadow-sm hover:bg-emerald-700'
                                            : 'bg-gray-50 text-gray-700 cursor-not-allowed'
                                        }
                                      `}
                                    >
                                      {time}
                                      {isBooked && (
                                        <div className="text-[10px] text-blue-600 mt-1">Prenotato</div>
                                      )}
                                    </button>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col items-center justify-center py-12">
                      <Calendar className="w-16 h-16 text-gray-200 mb-4" />
                      <p className="text-gray-500 text-center">
                        {!selectedPatientForBooking 
                          ? "Seleziona prima un paziente"
                          : "Seleziona una data per vedere gli orari disponibili"}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <DialogFooter className="mt-6 pt-4 border-t">
            <Button
              variant="outline"
              onClick={() => {
                setShowBookingDialog(false);
                setSelectedPatientForBooking(null);
                setBookingDate(null);
              }}
            >
              Annulla
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TherapistDashboard;