import React, { useState } from "react";
import { createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { ref, set, get } from "firebase/database";
import { auth, database, googleProvider } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { sendRegistrationEmail } from "../services/emailService";

const TherapistRegister = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    // Verifica l'accettazione dei termini e condizioni
    if (!termsAccepted) {
      setError("Devi accettare i termini e condizioni per procedere.");
      return;
    }

    try {
      // Creazione utente Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      // Salvataggio dati utente nel Realtime Database
      await set(ref(database, `users/${userId}`), {
        name,
        surname,
        email,
        userType: 'therapist',
        userId,
      });

      // Send registration confirmation email
      await sendRegistrationEmail(email, 'therapist');

      // Reindirizza al form di completamento per terapeuti
      navigate('/therapist-form');
    } catch (err) {
      setError('Errore nella registrazione. Riprova.');
    }
  };

  const handleGoogleRegister = async () => {
    if (!termsAccepted) {
      setError("Devi accettare i termini e condizioni per procedere.");
      return;
    }

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      // Check if user already exists
      const userRef = ref(database, `users/${user.uid}`);
      const snapshot = await get(userRef);
      
      if (snapshot.exists()) {
        if (snapshot.val().userType === 'therapist') {
          navigate("/dashboard-therapist");
          return;
        }
        setError("Account già registrato con un altro ruolo.");
        return;
      }

      // Create new therapist user
      await set(ref(database, `users/${user.uid}`), {
        name: user.displayName?.split(' ')[0] || '',
        surname: user.displayName?.split(' ').slice(1).join(' ') || '',
        email: user.email,
        userType: 'therapist',
        userId: user.uid,
      });

      await sendRegistrationEmail(user.email, 'therapist');
      navigate('/therapist-form');
    } catch (err) {
      setError('Errore nella registrazione con Google. Riprova.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleRegister} className="flex flex-col max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <div className="mb-5 flex justify-center">
          <img src="/logo.png" alt="Etherapy" className="h-[100px]" />
        </div>
        <h2 className="text-2xl font-bold mb-7 text-center text-emerald-700">Registrazione Terapeuta</h2>
        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="text"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          placeholder="Cognome"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-2 p-2 border rounded"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="mb-2 p-2 border rounded"
        />
        <div className="mb-2 flex items-center">
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
            className="mr-2"
          />
          <label>
            Accetto i <a href="/terms" className="text-blue-900">termini e condizioni</a>
          </label>
        </div>
        <button type="submit" className="text-white py-2 px-4 rounded bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-all">
          Registrati come Terapeuta
        </button>

        <div className="my-4 flex items-center">
          <div className="flex-1 border-t border-gray-300"></div>
          <span className="px-4 text-gray-500">oppure</span>
          <div className="flex-1 border-t border-gray-300"></div>
        </div>

        <button 
          type="button"
          onClick={handleGoogleRegister}
          className="flex items-center justify-center gap-2 py-2 px-4 border border-gray-300 rounded-lg hover:bg-gray-50 transition-all"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24">
            <path
              fill="#4285F4"
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
              fill="#34A853"
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
              fill="#FBBC05"
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
              fill="#EA4335"
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
          </svg>
          Registrati con Google
        </button>

        <p className="text-sm text-center mt-4">
          Hai già un account? <a href="/therapistlogin" className="text-blue-900">Accedi ora!</a>
        </p>
      </form>
    </div>
  );
};

export default TherapistRegister;