import React, { useState, useEffect, useRef } from 'react';
import { ref, onValue, push, set, update, get } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { MessageCircle, User, Send, LockKeyhole, LogOut, Search, ChevronUp, Calendar, Sun, Moon, Clock, Wallet, Video, X } from 'lucide-react';
import Sidebar from './Sidebar';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Calendar as CalendarComponent } from '../ui/calendar';
import ChatbotComponent from './ChatbotComponent';
import TherapistDetailsModal from './TherapistDetailsModal';
import { AlertDialogTrigger } from "../../components/ui/alert-dialog";
import CancelMatchDialog from "./CancelMatchDialog";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../ui/dialog";


// Component definitions remain the same
const ChatMessage = ({ message, isOwnMessage, isTherapistMessage }) => (
  <div className={`flex ${isOwnMessage ? 'justify-end' : 'justify-start'} mb-4`}>
    <div
      className={`max-w-[85%] sm:max-w-[70%] px-3 py-2 rounded-lg backdrop-blur-sm  ${isOwnMessage
          ? 'bg-white/90 text-right text-gray-800 z-10'
          : isTherapistMessage
            ? 'bg-gradient-to-r from-emerald-600 to-teal-600/90 text-white z-10'
            : 'bg-white/90 text-gray-800'
        }`}
    >
      <p className="text-sm">{message.text}</p>
      <span className="text-xs mt-1 block opacity-70">
        {new Date(message.timestamp).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })}
      </span>
    </div>
  </div>
);

const TimeSlotPicker = ({ availableSlots, onSelectTime }) => {
  const [selectedSlot, setSelectedSlot] = useState(null);

  const timeGroups = availableSlots.reduce((acc, slot) => {
    const hour = parseInt(slot.split(':')[0]);
    const period = hour < 12 ? 'Mattina' : hour < 17 ? 'Pomeriggio' : 'Sera';
    if (!acc[period]) acc[period] = [];
    acc[period].push(slot);
    return acc;
  }, {});

  if (!availableSlots || availableSlots.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-8 px-4 bg-gray-50 rounded-lg">
        <Calendar className="w-12 h-12 text-gray-400 mb-3" />
        <p className="text-gray-600 text-center">
          Non ci sono orari disponibili per questa data.
          <br />
          <span className="text-sm text-gray-500">Per favore seleziona un altro giorno.</span>
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {Object.entries(timeGroups).map(([period, slots]) => (
        <div key={period} className="space-y-3">
          <h4 className="text-sm font-medium text-gray-700 flex items-center gap-2">
            {period === 'Mattina' && <Sun className="w-4 h-4 text-amber-500" />}
            {period === 'Pomeriggio' && <Sun className="w-4 h-4 text-orange-500" />}
            {period === 'Sera' && <Moon className="w-4 h-4 text-indigo-500" />}
            {period}
          </h4>
          <div className="grid grid-cols-3 sm:grid-cols-4 gap-2">
            {slots.map((slot) => (
              <button
                key={slot}
                onClick={() => {
                  setSelectedSlot(slot);
                  onSelectTime(slot);
                }}
                className={`
                  text-sm py-2 px-3 rounded-lg transition-all duration-200
                  ${selectedSlot === slot
                    ? 'bg-emerald-600 text-white shadow-md shadow-emerald-100 scale-105'
                    : 'bg-white border border-emerald-100 text-emerald-800 hover:bg-emerald-50 hover:border-emerald-200'
                  }
                `}
              >
                {slot}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const PatientDashboard = () => {
  const [profile, setProfile] = useState(null);
  const [therapist, setTherapist] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [bookingError, setBookingError] = useState(null);
  const [isFirstInvoicePaid, setIsFirstInvoicePaid] = useState();
  const [showCancelAppointmentDialog, setShowCancelAppointmentDialog] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const chatEndRef = useRef(null);
  const [isFirstAppointment, setIsFirstAppointment] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [therapistAvailability, setTherapistAvailability] = useState({});
  const [showBookingSection, setShowBookingSection] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [availableDates, setAvailableDates] = useState(new Set());

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('No user ID found');
      return;
    }

    const userRef = ref(database, `users/${userId}`);
    const userListener = onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      if (userData) {
        setProfile({ id: userId, ...userData });

        if (userData.matchStatus === 'matched' && userData.matchedTherapistId) {
          const therapistRef = ref(database, `users/${userData.matchedTherapistId}`);
          onValue(therapistRef, (therapistSnapshot) => {
            const therapistData = therapistSnapshot.val();
            if (therapistData) {
              setTherapist({ id: userData.matchedTherapistId, ...therapistData });
            }
          });
        }
      }
    });

    const appointmentsRef = ref(database, `appointments/${userId}`);
    const appointmentsListener = onValue(appointmentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const appointmentsList = Object.entries(data).map(([id, appointment]) => ({
          ...appointment,
          id
        }));
        setAppointments(appointmentsList);
        setIsFirstAppointment(appointmentsList.length === 0);
      }
    });

    return () => {
      userListener();
      appointmentsListener();
    };
  }, []);

  // Single useEffect for therapist availability
  useEffect(() => {
    if (!therapist?.id) return;

    const availabilityRef = ref(database, `availability/${therapist.id}`);
    const availabilityListener = onValue(availabilityRef, (snapshot) => {
      const data = snapshot.val() || {};
      setTherapistAvailability(data);
      
      // Pre-calculate available dates starting from today
      const availableDatesSet = new Set();
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      Object.entries(data).forEach(([dateStr, slots]) => {
        if (slots && slots.length > 0) {
          const dateToCheck = new Date(dateStr);
          // Filter out slots that are in the past for today
          if (dateStr === today.toISOString().split('T')[0]) {
            const futureSlots = slots.filter(slot => {
              const [hours, minutes] = slot.split(':');
              const slotTime = new Date();
              slotTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
              return slotTime > now;
            });
            if (futureSlots.length > 0) {
              availableDatesSet.add(dateStr);
              // Update the available slots for today
              data[dateStr] = futureSlots;
            }
          } else if (dateToCheck > today) {
            availableDatesSet.add(dateStr);
          }
        }
      });
      setTherapistAvailability(data);
      setAvailableDates(availableDatesSet);
    });

    return () => availabilityListener();
  }, [therapist?.id]);

  useEffect(() => {
    if (profile?.matchStatus === 'matched' && therapist) {
      const messagesRef = ref(database, `chats/${profile.id}`);
      const messagesListener = onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setMessages(Object.values(data).sort((a, b) => a.timestamp - b.timestamp));
        }
      });

      return () => messagesListener();
    }
  }, [profile?.matchStatus, therapist, profile?.id]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() || !therapist) return;

    const chatId = `${profile.id}`;
    const messagesRef = ref(database, `chats/${chatId}`);
    const newMessageRef = push(messagesRef);

    const messageData = {
      text: newMessage,
      senderId: profile.id,
      senderType: 'patient',
      timestamp: Date.now(),
    };

    await set(newMessageRef, messageData);
    setNewMessage('');
  };

  const getAvailableSlotsForDate = (date) => {
    if (!date) return [];
    const dateStr = date.toISOString().split('T')[0];
    const slots = therapistAvailability[dateStr] || [];
    
    // If it's today, only return future slots
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const selectedDate = new Date(date);
    
    if (selectedDate.getTime() === today.getTime()) {
      return slots.filter(slot => {
        const [hours, minutes] = slot.split(':');
        const slotTime = new Date();
        slotTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
        return slotTime > now;
      });
    }
    
    return slots;
  };

  // Memoized function to check if a date has available slots
  const hasAvailableSlots = React.useCallback((date) => {
    const dateStr = date.toISOString().split('T')[0];
    return availableDates.has(dateStr);
  }, [availableDates]);

  // Memoized calendar disable function
  const isDateDisabled = React.useCallback((date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    
    // Convert input date to midnight for comparison
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);

    // Disable dates before today
    if (compareDate < today) return true;
    
    // Disable dates more than 30 days in the future
    const maxDate = new Date(today);
    maxDate.setDate(today.getDate() + 30);
    if (compareDate > maxDate) return true;

    // Only enable dates that have available slots
    return !hasAvailableSlots(date);
  }, [hasAvailableSlots]);

  const bookAppointment = async (timeSlot) => {
    if (!selectedDate) return setBookingError("Seleziona una data");
    if (!timeSlot) return setBookingError("Seleziona un orario");
    if (isBooking) return; // Prevent double booking

    try {
      setIsBooking(true);
      setBookingError(null);

      const appointmentData = {
        time: timeSlot,
        date: selectedDate.toISOString(),
        therapistId: therapist.id,
        therapistName: therapist.name,
        therapistSurname: therapist.surname,
        patientId: profile.id,
        name: profile.name,
        status: 'scheduled',
        createdAt: new Date().toISOString()
      };

      // Add surname only if it exists
      if (profile.surname) {
        appointmentData.surname = profile.surname;
      }

      const appointmentRef = ref(database, `appointments/${profile.id}`);
      const newAppointmentRef = push(appointmentRef);
      await set(newAppointmentRef, appointmentData);

      // Save invoice if it's not the first appointment
      if (!isFirstAppointment) {
        const invoiceData = {
          date: selectedDate.toISOString(),
          amount: 59,
          status: 'unpaid',
          therapistId: therapist.id,
          name: profile.name,
          therapistName: therapist.name,
          therapistSurname: therapist.surname,
        };

        // Add surname only if it exists
        if (profile.surname) {
          invoiceData.surname = profile.surname;
        }

        const invoiceRef = ref(database, `invoices/${profile.id}`);
        await set(push(invoiceRef), invoiceData);
      }

      // Remove the booked slot from therapist's availability
      const dateStr = selectedDate.toISOString().split('T')[0];
      const updatedSlots = (therapistAvailability[dateStr] || []).filter(slot => slot !== timeSlot);
      await set(ref(database, `availability/${therapist.id}/${dateStr}`), updatedSlots);

      setSelectedDate(null);
      setIsFirstAppointment(false);
      setShowBookingSection(false);
      alert("Appuntamento prenotato con successo!");
    } catch (error) {
      console.error('Error booking appointment:', error);
      setBookingError("Errore nella prenotazione");
    } finally {
      setIsBooking(false);
    }
  };

  const renderChat = () => {
    return (
      <>
        <div className="h-[300px] sm:h-[400px] overflow-y-auto p-3 sm:p-4 space-y-3 sm:space-y-4 z-10">
          {messages.map((msg, i) => (
            <ChatMessage
              key={i}
              message={msg}
              isOwnMessage={msg.senderId === profile?.id}
              isTherapistMessage={msg.senderType === 'therapist'}
            />
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="p-3 sm:p-4 border-t">
          <form onSubmit={sendMessage} className="flex gap-2">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Scrivi un messaggio..."
              className="flex-1 px-3 py-2 text-sm sm:text-base rounded-lg bg-white border-0 focus:ring-2 focus:ring-emerald-500 placeholder-gray-500"
            />
            <Button type="submit" className="bg-emerald-600 hover:bg-emerald-700">
              <Send className="w-4 h-4 sm:w-5 sm:h-5" />
            </Button>
          </form>
        </div>
      </>
    );
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      // Il reindirizzamento verrà gestito dal router/auth listener
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  const handleCancelMatch = async () => {
    try {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      // Aggiorna lo stato dell'utente
      await update(ref(database, `users/${userId}`), {
        matchStatus: 'pending',
        matchedTherapistId: null,
        lastUpdated: new Date().toISOString()
      });

      // Aggiorna lo stato del terapeuta
      if (profile?.matchedTherapistId) {
        const therapistRef = ref(database, `users/${profile.matchedTherapistId}/patients/${userId}`);
        await update(therapistRef, {
          status: 'cancelled',
          cancelledAt: new Date().toISOString()
        });
      }

      setShowCancelDialog(false);
      // La pagina si aggiornerà automaticamente grazie al listener sul profilo
    } catch (error) {
      console.error('Errore durante la cancellazione del match:', error);
      alert('Si è verificato un errore. Riprova più tardi.');
    }
  };

  const handleCancelAppointment = async (appointment) => {
    try {
      // Restore the time slot in therapist's availability
      const appointmentDate = new Date(appointment.date);
      const dateStr = appointmentDate.toISOString().split('T')[0];
      
      // Get current slots for that date
      const availabilityRef = ref(database, `availability/${appointment.therapistId}/${dateStr}`);
      const snapshot = await get(availabilityRef);
      const currentSlots = snapshot.val() || [];
      
      // Add the cancelled slot back and sort
      const updatedSlots = [...currentSlots, appointment.time].sort();
      
      // Update availability
      await set(availabilityRef, updatedSlots);
      
      // Update appointment status
      const appointmentRef = ref(database, `appointments/${profile.id}/${appointment.id}`);
      await update(appointmentRef, {
        status: 'cancelled',
        cancelledAt: new Date().toISOString()
      });

      setShowCancelAppointmentDialog(false);
      setSelectedAppointment(null);
      alert("Appuntamento cancellato con successo");
    } catch (error) {
      console.error('Error cancelling appointment:', error);
      alert("Errore durante la cancellazione dell'appuntamento");
    }
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-50">
      <div className="hidden md:block fixed inset-y-0 left-0 w-[280px] bg-white border-r border-gray-200 z-20">
        <Sidebar />
      </div>

      <div className="md:hidden">
        <Sidebar />
      </div>

      <div className="flex-1 w-full md:pl-[280px]">
        <div className="w-full p-4 md:p-8">
          <header className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 bg-white rounded-xl p-4 md:p-6 shadow-sm w-full mt-16 md:mt-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <div className="flex items-center gap-4">
                <div className="w-12 md:w-14 h-12 md:h-14 rounded-full overflow-hidden bg-gray-100 ring-2 ring-emerald-500/20">
                  {profile?.photoURL ? (
                    <img 
                      src={profile.photoURL} 
                      alt="Profile" 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <User className="w-6 md:w-7 h-6 md:h-7 text-gray-400" />
                    </div>
                  )}
                </div>
                <div>
                  <h1 className="text-xl md:text-2xl font-serif text-emerald-800 font-semibold">
                    Il Tuo Percorso
                  </h1>
                  <p className="text-gray-500 text-sm">
                    {profile?.name} {profile?.surname}
                  </p>
                </div>
              </div>
              <button
                onClick={handleLogout}
                className="md:hidden flex items-center justify-center gap-2 px-4 py-2 text-gray-600 hover:text-red-600 transition-colors rounded-lg hover:bg-red-50"
              >
                <LogOut className="w-5 h-5" />
              </button>
            </div>
            <button
              onClick={handleLogout}
              className="hidden md:flex items-center justify-center gap-2 px-4 py-2 text-gray-600 hover:text-red-600 transition-colors rounded-lg hover:bg-red-50"
            >
              <LogOut className="w-5 h-5" />
              <span>Logout</span>
            </button>
          </header>

          {profile?.matchStatus === 'matched' ? (
            <>
              {/* Therapist Card - Always visible at the top on mobile */}
              <div className="lg:hidden mb-8 sm:mb-10">
                <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                  <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                    <div className="flex justify-between items-center">
                      <CardTitle className="text-base sm:text-lg font-medium flex items-center gap-2">
                        Il tuo terapeuta
                      </CardTitle>
                      <button
                        onClick={() => setShowCancelDialog(true)}
                        className="text-white/80 hover:text-white text-sm flex items-center gap-1 transition-colors"
                      >
                        Cambia terapeuta
                      </button>
                    </div>
                  </CardHeader>
                  <CardContent className="p-4 mt-4 sm:p-6">
                    <div className="flex items-start gap-4 sm:gap-6">
                      <div className="w-16 h-16 sm:w-20 sm:h-20 bg-gradient-to-br from-emerald-100 to-teal-50 rounded-full flex items-center justify-center overflow-hidden">
                        {therapist?.photoURL ? (
                          <img 
                            src={therapist.photoURL} 
                            alt={`${therapist.name} ${therapist.surname}`} 
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <User className="w-8 h-8 sm:w-10 sm:h-10 text-emerald-600" />
                        )}
                      </div>
                      <div className="flex-1">
                        <p className="text-sm sm:text-base text-emerald-800">
                          <span className="font-medium">Nome:</span> {therapist?.name}
                        </p>
                        <p className="text-sm sm:text-base text-emerald-800">
                          <span className="font-medium">Cognome:</span> {therapist?.surname}
                        </p>
                        <div className="mt-4 flex flex-col gap-2">
                          <Button
                            variant="outline"
                            onClick={() => setIsDetailsModalOpen(true)}
                            className="w-full bg-white hover:bg-emerald-50 border-emerald-200 text-emerald-700"
                          >
                            Più dettagli
                          </Button>
                          <Button
                            onClick={() => setShowBookingSection(true)}
                            className="w-full bg-emerald-600 hover:bg-emerald-700 text-white flex items-center justify-center gap-2"
                          >
                            <Calendar className="w-4 h-4" />
                            Prenota una seduta
                          </Button>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* Main Grid Layout */}
              <div className="grid grid-cols-1 lg:grid-cols-[2fr,1fr] gap-4 sm:gap-6">
                <div className="space-y-4 md:space-y-6">
                  <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                      <CardTitle className="text-base sm:text-lg font-medium flex items-center gap-2">
                        <Calendar className="w-4 h-4 sm:w-5 sm:h-5" />
                        I Tuoi Appuntamenti
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="p-0">
                      {appointments.length > 0 ? (
                        <ul className="divide-y divide-emerald-100">
                          {appointments.map((appointment) => (
                            <li key={appointment.id} className="p-4 flex justify-between items-center hover:bg-emerald-50/50 transition-colors">
                              <div className="flex items-center gap-4">
                                <div className="text-emerald-700">
                                  <p className="text-sm font-medium">
                                    {new Date(appointment.date).toLocaleDateString('it-IT')}
                                  </p>
                                  <p className="text-xs text-emerald-600">
                                    {appointment.time}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center gap-2">
                                <span
                                  className={`text-xs px-3 py-1 rounded-full ${
                                    appointment.status === 'scheduled'
                                      ? 'bg-emerald-100 text-emerald-700'
                                      : appointment.status === 'cancelled'
                                      ? 'bg-red-100 text-red-700'
                                      : 'bg-gray-100 text-gray-700'
                                  }`}
                                >
                                  {appointment.status === 'scheduled' ? 'Confermato' : 
                                   appointment.status === 'cancelled' ? 'Cancellato' : appointment.status}
                                </span>
                                {appointment.status === 'scheduled' && new Date(appointment.date) > new Date() && (
                                  <button
                                    onClick={() => {
                                      setSelectedAppointment(appointment);
                                      setShowCancelAppointmentDialog(true);
                                    }}
                                    className="text-red-600 hover:text-red-700 text-sm"
                                  >
                                    Cancella
                                  </button>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="p-6 text-center text-gray-500">
                          Non hai appuntamenti prenotati
                        </div>
                      )}
                    </CardContent>
                  </Card>

                  <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                      <CardTitle className="text-base sm:text-lg font-medium flex items-center gap-2">
                        <MessageCircle className="w-4 h-4 sm:w-5 sm:h-5" />
                        Chat con {therapist?.name} {therapist?.surname}
                      </CardTitle>
                    </CardHeader>
                    <CardContent className="p-0">
                      {renderChat()}
                    </CardContent>
                  </Card>
                </div>

                {/* Therapist Card - Only visible on desktop */}
                <div className="hidden lg:block space-y-4 md:space-y-6">
                  <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
                    <CardHeader className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg p-4 sm:p-6">
                      <div className="flex justify-between items-center">
                        <CardTitle className="text-base sm:text-lg font-medium flex items-center gap-2">
                          Il tuo terapeuta
                        </CardTitle>
                        <button
                          onClick={() => setShowCancelDialog(true)}
                          className="text-white/80 hover:text-white text-sm flex items-center gap-1 transition-colors"
                        >
                          Cambia terapeuta
                        </button>
                      </div>
                    </CardHeader>
                    <CardContent className="p-4 sm:p-6">
                      <div className="flex items-start gap-4 sm:gap-6">
                        <div className="w-16 h-16 sm:w-20 sm:h-20 bg-gradient-to-br from-emerald-100 to-teal-50 rounded-full flex items-center justify-center overflow-hidden">
                          {therapist?.photoURL ? (
                            <img 
                              src={therapist.photoURL} 
                              alt={`${therapist.name} ${therapist.surname}`} 
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <User className="w-8 h-8 sm:w-10 sm:h-10 text-emerald-600" />
                          )}
                        </div>
                        <div className="flex-1">
                          <p className="text-sm sm:text-base text-emerald-800">
                            <span className="font-medium">Nome:</span> {therapist?.name}
                          </p>
                          <p className="text-sm sm:text-base text-emerald-800">
                            <span className="font-medium">Cognome:</span> {therapist?.surname}
                          </p>
                          <div className="mt-4 flex flex-col gap-2">
                            <Button
                              variant="outline"
                              onClick={() => setIsDetailsModalOpen(true)}
                              className="w-full bg-white hover:bg-emerald-50 border-emerald-200 text-emerald-700"
                            >
                              Più dettagli
                            </Button>
                            <Button
                              onClick={() => setShowBookingSection(true)}
                              className="w-full bg-emerald-600 hover:bg-emerald-700 text-white flex items-center justify-center gap-2"
                            >
                              <Calendar className="w-4 h-4" />
                              Prenota una seduta
                            </Button>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </>
          ) : (
            <Card className="bg-white rounded-xl shadow-sm overflow-hidden">
              <CardContent className="p-8 flex flex-col items-center justify-center text-center">
                <div className="w-16 h-16 sm:w-20 sm:h-20 bg-emerald-100 rounded-full flex items-center justify-center mb-4">
                  <Search className="w-8 h-8 sm:w-10 sm:h-10 text-emerald-600" />
                </div>
                <h2 className="text-xl sm:text-2xl font-medium text-emerald-800 mb-3">
                  Stiamo cercando il terapeuta giusto per te
                </h2>
                <p className="text-gray-600 max-w-md">
                  Sulla base delle tue preferenze e necessità, stiamo selezionando il terapeuta più adatto. 
                  Riceverai una notifica non appena avremo trovato il match perfetto.
                </p>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <TherapistDetailsModal
        therapist={therapist}
        open={isDetailsModalOpen}
        onOpenChange={setIsDetailsModalOpen}
      />

      <CancelMatchDialog
        open={showCancelDialog}
        onOpenChange={setShowCancelDialog}
        onConfirm={handleCancelMatch}
      />

      <Dialog open={showBookingSection} onOpenChange={setShowBookingSection}>
        <DialogContent className="max-w-4xl max-h-[90vh] flex flex-col p-6">
          <DialogHeader className="px-2">
            <DialogTitle className="text-lg font-medium text-emerald-800 flex items-center gap-2">
              <Calendar className="w-5 h-5" />
              Prenota una seduta
            </DialogTitle>
          </DialogHeader>

          <div className="flex-1 overflow-y-auto px-1">
            <div className="grid md:grid-cols-[300px,1fr] gap-6 mt-4">
              <div className="space-y-4">
                <div className="bg-emerald-50/50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-emerald-800 mb-2">Dettagli Terapeuta</h4>
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 rounded-full bg-emerald-100 flex items-center justify-center">
                      {therapist?.photoURL ? (
                        <img 
                          src={therapist.photoURL} 
                          alt={therapist.name} 
                          className="w-full h-full rounded-full object-cover"
                        />
                      ) : (
                        <User className="w-6 h-6 text-emerald-600" />
                      )}
                    </div>
                    <div>
                      <p className="text-sm font-medium text-emerald-900">
                        {therapist?.name} {therapist?.surname}
                      </p>
                      <p className="text-xs text-emerald-600">
                        {isFirstAppointment ? 'Prima seduta' : 'Seduta di follow-up'}
                      </p>
                    </div>
                  </div>
                </div>

                <CalendarComponent
                  mode="single"
                  selected={selectedDate}
                  onSelect={setSelectedDate}
                  disabled={isDateDisabled}
                  className="rounded-lg border border-emerald-100"
                />
              </div>

              <div className="space-y-4">
                {selectedDate ? (
                  <>
                    <div className="flex items-center gap-2 text-sm text-emerald-700 mb-4">
                      <Calendar className="w-4 h-4" />
                      <span>
                        {selectedDate.toLocaleDateString('it-IT', {
                          weekday: 'long',
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric'
                        })}
                      </span>
                    </div>
                    <TimeSlotPicker 
                      availableSlots={getAvailableSlotsForDate(selectedDate)} 
                      onSelectTime={(slot) => {
                        bookAppointment(slot);
                        setShowBookingSection(false);
                      }}
                    />
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center py-12 px-4">
                    <Calendar className="w-16 h-16 text-emerald-200 mb-4" />
                    <p className="text-gray-500 text-center">
                      Seleziona una data dal calendario per vedere gli orari disponibili
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <DialogFooter className="mt-6 pt-4 border-t">
            <Button
              variant="outline"
              onClick={() => {
                setShowBookingSection(false);
                setSelectedDate(null);
              }}
            >
              Annulla
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={showCancelAppointmentDialog} onOpenChange={setShowCancelAppointmentDialog}>
        <DialogContent className="sm:max-w-md p-6">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-xl">Cancella Appuntamento</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 py-4">
            <p className="text-sm text-gray-600">
              Sei sicuro di voler cancellare questo appuntamento?
            </p>
            {selectedAppointment && (
              <div className="bg-gray-50 p-6 rounded-lg space-y-3">
                <p className="text-sm font-medium">Data: {new Date(selectedAppointment.date).toLocaleDateString('it-IT')}</p>
                <p className="text-sm">Ora: {selectedAppointment.time}</p>
              </div>
            )}
          </div>
          <DialogFooter className="mt-6 pt-4 gap-3">
            <Button
              variant="outline"
              onClick={() => {
                setShowCancelAppointmentDialog(false);
                setSelectedAppointment(null);
              }}
              className="px-5"
            >
              Annulla
            </Button>
            <Button
              variant="destructive"
              onClick={() => selectedAppointment && handleCancelAppointment(selectedAppointment)}
              className="bg-red-600 hover:bg-red-700 text-white px-5"
            >
              Conferma Cancellazione
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PatientDashboard;