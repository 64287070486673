import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import { auth, database } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

const PatientRegister = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create base user data
      const userData = {
        name: name,
        surname: surname,
        email: email,
        userType: 'patient',
        userId: user.uid,
        profileComplete: false,
        createdAt: new Date().toISOString()
      };

      // Save user data to database
      await set(ref(database, `users/${user.uid}`), userData);
      
      // Redirect to the patient form
      navigate("/patient-form");
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        setError("Email già registrata. Prova ad accedere.");
      } else if (err.code === "auth/weak-password") {
        setError("La password deve essere di almeno 6 caratteri.");
      } else {
        setError("Errore durante la registrazione. Riprova.");
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="flex flex-col max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <div className="mb-5 flex justify-center">
          <img src="/logo.png" alt="Etherapy" className="h-[100px]" />
        </div>
        <h2 className="text-2xl font-bold mb-7 text-center text-emerald-700">Registrazione Paziente</h2>
        {error && <p className="text-red-500 mb-2 text-center">{error}</p>}
        
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nome"
          className="mb-2 p-2 border rounded"
          required
        />
        <input
          type="text"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          placeholder="Cognome"
          className="mb-2 p-2 border rounded"
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="mb-2 p-2 border rounded"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="mb-2 p-2 border rounded"
          required
        />
        
        <button 
          type="submit" 
          className="text-white py-2 px-4 rounded bg-gradient-to-r from-emerald-500 to-teal-500 text-white rounded-lg hover:from-emerald-600 hover:to-teal-600 transition-all"
        >
          Registrati
        </button>

        <p className="text-sm text-center mt-4">
          Hai già un account? <a href="/patient-login" className="text-blue-900">Accedi</a>
        </p>
      </form>
    </div>
  );
};

export default PatientRegister;